import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Check, Clear } from "@material-ui/icons";
import { Box, Tooltip } from "@material-ui/core";

const cardWidth = 320;
const cardHeight = 320;
const imageMaxHeight = 240;

const useStyles = makeStyles({
  root: {
    cardWidth,
  },
});

export interface FileProps {
  category: string;
  collections: Array<{ collectionName: string; collectionId: string }>;
  description: string;
  fileSize: string; // in bytes
  fileStatus: { statusTitle: string };
  fileType: string; // file extension in uppercase letters
  id: string;
  imagePath: string;
  imageHeight: string;
  imageWidth: string;
  keywords: string;
  originalFilename: string;
  subject: string;
  uploadDate: string;
  licenses: Array<{ licenseName: string; licenseProvider: string }>;
}

export function MediaCard(props: { file: FileProps; imageClicked: Function; selected: Boolean }) {
  const classes = useStyles();
  const file = props.file;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      cardWidth,
      fontSize: theme.typography.pxToRem(12),
      border: "2px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <Card
      onClick={() => props.imageClicked(file)}
      className={classes.root}
      style={{ backgroundColor: props.selected ? "#16875D" : "white", height: cardHeight, width: cardWidth }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt={file.description}
          height={
            // cardWidth * (parseInt(file.imageHeight) / parseInt(file.imageWidth)) <= imageMaxHeight
            //   ? cardWidth * (parseInt(file.imageHeight) / parseInt(file.imageWidth))
            //   : imageMaxHeight
            imageMaxHeight
          }
          image={file.imagePath}
          title={file.description}
        />
        <HtmlTooltip
          disableFocusListener
          disableTouchListener
          placement="right"
          arrow
          title={
            <React.Fragment>
              <Typography style={{ textAlign: "center" }} variant="h6" color="textPrimary">
                {file.subject}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p">
                <strong>Beschreibung:</strong> {file.description} <br />
                <strong>Kategorie:</strong> {file.category} <br />
                <strong>Status:</strong> {file.fileStatus.statusTitle} <br />
                <strong>Upload Datum:</strong> {file.uploadDate} <br />
                <strong>Lizenz:</strong> {file.licenses.map((license) => license.licenseName).toString()} <br />
                <strong>Keywords:</strong> {file.keywords}
              </Typography>
            </React.Fragment>
          }
        >
          <CardContent>
            <Typography style={{ textAlign: "center" }} variant="h6">
              {file.subject}
            </Typography>
          </CardContent>
        </HtmlTooltip>
        {/* <Box style={{ position: "absolute", top: cardHeight - 25, left: cardWidth - 25 }}>
          {props.selected ? <Check></Check> : <Clear></Clear>}
        </Box> */}
        <CardActions></CardActions>
      </CardActionArea>
    </Card>
  );
}
