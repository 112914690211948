import React, { Component } from "react";
import { PageExtensionSDK } from "@contentful/app-sdk";
import { AppInstallationParameters } from "./ConfigScreen";
import axios from "axios";
import LocaleStorageManager from "./helpers/localeStorageManager";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { FileProps, MediaCard } from "./PageComponents/MediaCard";
import { ArrowBack, InfoOutlined, WarningRounded } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Theme, withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Autocomplete, Pagination } from "@material-ui/lab";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2E75D4",
    },
    secondary: {
      main: "#BF3045",
    },
    error: {
      main: "#BF3045",
    },
    warning: {
      main: "#F79B0C",
    },
    info: {
      main: "#2E75D4",
    },
    success: {
      main: "#16875D",
    },
  },
});

const useStyles = (theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  success: {
    color: "white",
    backgroundColor: "#16875D",
  },
  warning: {
    color: "white",
    backgroundColor: "#F79B0C",
  },
  info: {
    color: "white",
    backgroundColor: "#2E75D4",
  },
});

interface PageProps {
  sdk: PageExtensionSDK;
  classes: any;
}

interface InvocationParams {
  path: string;
}

interface ExistingFile {
  contentfulId: string;
  version: number;
  subject: string;
  pixxioId: string;
}

interface Collection {
  collectionName: string;
  quantity: string;
  description: string;
  files: [];
  id: string;
  modifyDate: string;
}

interface PageState {
  invocationParams: InvocationParams;
  installationParams: AppInstallationParameters;
  pixxioTagId: string;
  refreshToken: string;
  loggedIn: boolean;
  user: {
    userName: string;
    preName: string;
    surName: string;
    email: string;
  };
  collections: Array<any>;
  collectionsQuantity: number;
  collection: Collection | undefined;
  files: Array<FileProps>;
  filesQuantity: number;
  selectedFiles: Array<FileProps>;
  existingFiles: {
    assets: Array<ExistingFile>;
    mediaAssets: Array<ExistingFile>;
    files: Array<FileProps>;
  };
  failedFiles: Array<FileProps>;
  media: [];
  selectedTab: number;
  page: {
    files: number;
    collections: number;
  };
  loading: boolean;
  progress: number;
  snackbar: {
    open: boolean;
    message: string;
    severity: string;
  };
  confirmDialogOpen: boolean;
  resultDialogOpen: boolean;
  searchBar: Array<string>;
  fileStati: Array<FileStatus>;
  selectedFileStati: Array<FileStatus>;
  categories: Array<string>;
  selectedCategories: Array<string>;
  // keywords: Array<string>;
  // selectedKeywords: Array<string>;
  [key: string]: any;
}

interface FileStatus {
  id: string;
  statusTitle: string;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "2px solid #dadde9",
  },
}))(Tooltip);

const ASSET_PREFIX = "pixxio-asset-";
const MEDIA_ASSET_PREFIX = "pixxio-mediaAsset-";
const PAGE_SIZE = 20;
const FILE_SIZE_LIMIT = 10 * 1000 * 1000; // 10 MB
const PIXXIO_FILE_PROPS = [
  "id",
  "originalFilename",
  "keywords",
  "uploadDate",
  "category",
  "imagePath",
  "imageHeight",
  "imageWidth",
  "subject",
  "description",
  "fileSize",
  "fileType",
  "formatType",
  "fileStatus",
  "collections",
  "licenses",
];
const FORMAT_TYPE = ["webimage", "raw", "image", "other"];

let localeStorageManager: LocaleStorageManager;

class Page extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    const invocationParams = props.sdk.parameters.invocation as InvocationParams;
    const installationParams = props.sdk.parameters.installation as AppInstallationParameters;

    localeStorageManager = new LocaleStorageManager(props.sdk.ids.space);

    // check if user is logged in
    let loggedIn = false;
    if (localeStorageManager.get("refreshToken") != null) {
      loggedIn = true;
    }

    this.state = {
      invocationParams,
      installationParams,
      pixxioTagId: "",
      refreshToken: "",
      loggedIn,
      user: {
        userName: "",
        preName: "",
        surName: "",
        email: "",
      },
      collections: [],
      collectionsQuantity: 0,
      collection: undefined,
      files: [],
      filesQuantity: 0,
      media: [],
      selectedFiles: [],
      existingFiles: {
        assets: [],
        mediaAssets: [],
        files: [],
      },
      failedFiles: [],
      selectedTab: 0,
      page: {
        files: 1,
        collections: 1,
      },
      loading: false,
      progress: 0,
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
      confirmDialogOpen: false,
      resultDialogOpen: false,
      searchBar: [],
      fileStati: [],
      selectedFileStati: [],
      categories: [],
      selectedCategories: [],
      // keywords: [],
      // selectedKeywords: [],
    };

    axios.defaults.baseURL = this.state.installationParams.pixxioApiUrl;

    // intercept requests and check if access Token is present
    axios.interceptors.request.use(
      async (config) => {
        if (config.params?.accessToken === undefined && config.url !== "/accessToken") {
          await this.getAccessToken();
          config.params = { ...config.params, accessToken: localeStorageManager.get("accessToken") };
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.getCollections = this.getCollections.bind(this);
    this.getCollection = this.getCollection.bind(this);
    this.imageClicked = this.imageClicked.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.importFiles = this.importFiles.bind(this);
    this.filesPageChange = this.filesPageChange.bind(this);
    this.collectionsPageChange = this.collectionsPageChange.bind(this);
    this.searchMedia = this.searchMedia.bind(this);
  }

  setStateAsync(state: any) {
    return new Promise((resolve: any) => {
      this.setState(state, resolve);
    });
  }

  async searchMedia(newSearch = true) {
    if (newSearch) {
      this.setState({ files: [], filesQuantity: 0, selectedFiles: [], page: { ...this.state.page, files: 1 } });
    }
    let response = await axios.get("/files", {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
        options: {
          pagination: `${PAGE_SIZE}-${this.state.page.files}`,
          sortBy: "subject",
          searchTerm: this.state.searchBar.toString(),
          fileStatus: this.state.selectedFileStati.map((status) => status.id),
          category: this.state.selectedCategories.toString(),
          formatType: FORMAT_TYPE,
          fields: PIXXIO_FILE_PROPS,
        },
      },
    });
    if (response.data.status === "200") {
      let files = response.data.files;
      let filesQuantity = response.data.quantity;
      this.setState({ files, filesQuantity });
      console.log("Search result:", files);
      if (newSearch) {
        this.setState({ snackbar: { open: true, message: `${filesQuantity} Dateien gefunden.`, severity: "info" } });
      }
      return true;
    }
    this.setState({ snackbar: { open: true, message: "Fehler bei der Suche!", severity: "error" } });
    console.error(response?.data);
    return false;
  }

  async getFileStati() {
    let response = await axios.get("/statuses", {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
        options: {
          pagination: `100-1`,
          sortBy: "statusTitle",
          fields: ["statusTitle", "id"],
        },
      },
    });
    if (response.data.status === "200") {
      let fileStati: Array<FileStatus> = response.data.statuses;
      fileStati.push({ id: "none", statusTitle: "Kein Status" }); // add "none" option
      fileStati.sort((a, b) => a.statusTitle.localeCompare(b.statusTitle));
      this.setState({ fileStati });
      return true;
    }
    this.setState({ snackbar: { open: true, message: "Fehler beim Laden der Dateien Stati!", severity: "error" } });
    console.error(response?.data);
    return false;
  }

  async getCategories() {
    let response = await axios.get("/categories", {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
      },
    });
    if (response.data.status === "200") {
      let categories = response.data.categories;
      categories.sort();
      this.setState({ categories });
      return true;
    }
    this.setState({ snackbar: { open: true, message: "Fehler beim Laden der Kategorien!", severity: "error" } });
    console.error(response?.data);
    return false;
  }

  // async getKeywords() {
  //   let response = await axios.get("/keywords", {
  //     params: {
  //       accessToken: localeStorageManager.get("accessToken"),
  //       options: {
  //         pagination: `100-1`,
  //         sortBy: "keyword",
  //         fields: ["keyword", "id"],
  //       },
  //     },
  //   });
  //   if (response.data.status === "200") {
  //     let keywords = response.data.keywords.map((value: any) => value.keyword);
  //     this.setState({ keywords });
  //     console.log("keywords: ", keywords);
  //     return true;
  //   }
  //   this.setState({ snackbar: { open: true, message: "Fehler beim Laden der Keywords!", severity: "error" } });
  //   console.log(response?.data);
  //   return false;
  // }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }
  async handleChangeTab(event: React.ChangeEvent<{}>, value: number) {
    this.setState({
      selectedTab: value,
      files: [],
      selectedFiles: [],
      collection: undefined,
      page: { ...this.state.page, files: 1 },
    });
    if (value === 1) {
      await this.getCollections();
    }
  }

  async filesPageChange(event: any, value: number) {
    await this.setStateAsync({
      page: {
        ...this.state.page,
        files: value,
      },
    });
    if (this.state.selectedTab === 0) {
      await this.searchMedia(false);
    } else {
      if (this.state?.collection?.id) {
        await this.getCollection(this.state.collection.id);
      }
    }
  }

  async collectionsPageChange(event: React.ChangeEvent<unknown>, value: number) {
    await this.setStateAsync({
      page: {
        ...this.state.page,
        collections: value,
      },
    });
    await this.getCollections();
  }

  async handleLogin(event: any) {
    localeStorageManager.set("refreshToken", this.state.refreshToken, 24 * 365);
    if (await this.getAccessToken()) {
      this.setState({ snackbar: { open: true, message: "Anmeldung erfolgreich!", severity: "info" } });
    }
    event.preventDefault();
  }

  async getAccessToken() {
    let refreshToken = localeStorageManager.get("refreshToken");

    if (refreshToken == null) {
      return this.setState({ loggedIn: false });
    }

    let response = await axios.post("/accessToken", {
      apiKey: this.state.installationParams.pixxioApiKey,
      refreshToken: refreshToken,
    });
    if (response.data.status === "200") {
      localeStorageManager.set("accessToken", response.data.accessToken, 1);
      localeStorageManager.set("refreshToken", refreshToken, 24 * 365);
      let loggedIn = await this.getUser();
      if (loggedIn) {
        this.getFileStati();
        this.getCategories();
      }
      return loggedIn;
    } else if (response.data.errorcode === "2004") {
      this.setState({ snackbar: { open: true, message: "Login fehlgeschlagen! API Key ist ungültig", severity: "error" } });
    } else if (response.data.errorcode === "2005") {
      this.setState({
        snackbar: { open: true, message: "Login fehlgeschlagen! Refresh Token ist ungültig.", severity: "error" },
      });
    } else if (response.data.errorcode === "2008") {
      this.setState({
        snackbar: { open: true, message: "Login fehlgeschlagen! User ist als inaktiv gesetzt.", severity: "error" },
      });
    } else {
      this.setState({ snackbar: { open: true, message: "Login fehlgeschlagen!", severity: "error" } });
    }
    console.error(response?.data);
    this.logout();
    return false;
  }

  logout() {
    localeStorageManager.remove("accessToken");
    localeStorageManager.remove("refreshToken");
    this.setState({ loggedIn: false });
    this.setState({ refreshToken: "" });
    this.setState({
      user: {
        userName: "",
        preName: "",
        surName: "",
        email: "",
      },
      selectedFiles: [],
      page: { ...this.state.page, files: 1 },
      selectedCategories: [],
      selectedFileStati: [],
      searchBar: [],
      files: [],
    });
  }

  async getUser() {
    let response = await axios.get("/users/current", {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
        options: { fields: ["preName", "surName", "userName", "email"] },
      },
    });

    if (response.data.status === "200") {
      let user = {
        userName: response.data.userName,
        preName: response.data.preName,
        surName: response.data.surName,
        email: response.data.email,
      };
      this.setState({ user, loggedIn: true });
      return true;
    }
    console.error(response?.data);
    return false;
  }

  async componentDidMount() {
    await this.getAccessToken();
    await this.getPixxioTagId();
  }

  async getCollections() {
    let response = await axios.get("/collections", {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
        options: { pagination: `${PAGE_SIZE}-${this.state.page.collections}`, sortBy: "collectionName" },
      },
    });
    if (response.data.status === "200") {
      let collections = response.data.collections;
      let collectionsQuantity = response.data.quantity;
      this.setState({ collections, collectionsQuantity });
      console.log("Collections:", this.state.collections);
      return true;
    }
    this.setState({ snackbar: { open: true, message: "Fehler beim Laden der Collections!", severity: "error" } });
    console.error(response?.data);
    return false;
  }

  async getCollection(id: string) {
    let response = await axios.get("/collections/" + id, {
      params: {
        accessToken: localeStorageManager.get("accessToken"),
        options: {
          pagination: `${PAGE_SIZE}-${this.state.page.files}`,
          sortBy: "subject",
          fields: PIXXIO_FILE_PROPS,
          formatType: FORMAT_TYPE,
        },
      },
    });
    if (response.data.status === "200") {
      let collection = response.data;
      let files = collection.files;
      let filesQuantity = response.data.quantity;
      this.setState({ collection, files, filesQuantity });
      console.log("Collection:", this.state.collection);
      return true;
    }
    this.setState({ snackbar: { open: true, message: "Fehler beim Laden der Collection!", severity: "error" } });
    console.error(response?.data);
    return false;
  }

  imageClicked(file: FileProps) {
    if (this?.state?.selectedFiles?.find((selectedFile) => selectedFile.id === file.id)) {
      this.setState({
        selectedFiles: this?.state?.selectedFiles.filter((selectedFile) => selectedFile.id !== file.id) ?? [],
      });
    } else {
      this.setState({
        selectedFiles: [...(this.state.selectedFiles as any), file],
      });
    }
  }

  async existingFiles() {
    try {
      if (this.state.selectedFiles === undefined) {
        this.setState({ snackbar: { open: true, message: "Keine Dateien ausgewählt!", severity: "info" } });

        return false;
      }
      const fileIds: string[] = this.state.selectedFiles.map((file) => file.id);
      const assetIds = fileIds.map((fileId) => ASSET_PREFIX + fileId).toString();
      const mediaAssetIds = fileIds.map((fileId) => MEDIA_ASSET_PREFIX + fileId).toString();

      const assets = [],
        mediaAssets = [];

      let skip = 0;
      let moreToFetch = true;
      while (moreToFetch) {
        const response: any = await this.props.sdk.space.getAssets({
          limit: 1000,
          skip,
          "sys.id[in]": assetIds,
        });
        assets.push(...response.items);

        skip += 1000;
        moreToFetch = skip < response.total;
      }

      skip = 0;
      moreToFetch = true;
      while (moreToFetch) {
        const response: any = await this.props.sdk.space.getEntries({
          limit: 1000,
          skip,
          "sys.id[in]": mediaAssetIds,
        });
        mediaAssets.push(...response.items);

        skip += 1000;
        moreToFetch = skip < response.total;
      }
      console.log("Existing assets: ", assets);
      console.log("Existing mediaAssets: ", mediaAssets);

      const existingAssets: Array<ExistingFile> = [];
      const existingMediaAssets: Array<ExistingFile> = [];
      const existingFiles = [];
      for (const file of this.state.selectedFiles) {
        let existingAsset = assets.find((asset: any) => ASSET_PREFIX + file.id === asset.sys.id);
        let existingMediaAsset = mediaAssets.find((mediaAsset: any) => MEDIA_ASSET_PREFIX + file.id === mediaAsset.sys.id);
        if (existingAsset !== undefined) {
          existingAssets.push({
            contentfulId: existingAsset.sys.id,
            version: existingAsset.sys.version,
            subject: file.subject,
            pixxioId: file.id,
          });
        }
        if (existingMediaAsset !== undefined) {
          existingMediaAssets.push({
            contentfulId: existingMediaAsset.sys.id,
            version: existingMediaAsset.sys.version,
            subject: file.subject,
            pixxioId: file.id,
          });
        }
        if (existingAsset !== undefined && existingMediaAsset !== undefined) {
          existingFiles.push(file);
        }
      }
      this.setState({
        existingFiles: {
          assets: existingAssets,
          mediaAssets: existingMediaAssets,
          files: existingFiles,
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async confirmImport() {
    try {
      this.setState({
        existingFiles: {
          assets: [],
          mediaAssets: [],
          files: [],
        },
      });

      // check if some files were already imported once
      if (this.state.selectedFiles === undefined) {
        this.setState({ snackbar: { open: true, message: "Keine Dateien ausgewählt!", severity: "info" } });
        return false;
      }
      await this.existingFiles();
      this.setState({ confirmDialogOpen: true });
    } catch (error) {
      console.error(error);
    }
  }

  async getPixxioTagId() {
    let { items } = await this.props.sdk.space.readTags(0, 1000);
    let pixxioTag = items.find((tag) => tag.sys.id === "pixxio");
    if (pixxioTag === undefined) {
      pixxioTag = await this.props.sdk.space.createTag("pixxio", "Pixxio", "public");
      console.log("Pixxio tag created");
    }
    this.setState({ pixxioTagId: pixxioTag.sys.id });
  }

  truncateString(str: string, max: number, suffix = "") {
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  }

  async importFiles() {
    if (this.state.selectedFiles === undefined) {
      this.setState({ snackbar: { open: true, message: "Keine Dateien ausgewählt!", severity: "info" } });
      return false;
    }
    this.setState({ loading: true, confirmDialogOpen: false });
    fileLoop: for (const [index, file] of this.state.selectedFiles.entries()) {
      try {
        let quality = 90;
        let imageWidth = parseInt(file.imageWidth);
        let fileTooBig = true;
        let downloadPath = "";
        while (fileTooBig) {
          let response = await axios.get(`/files/${file.id}/convert`, {
            params: {
              accessToken: localeStorageManager.get("accessToken"),
              options: { responseType: "path", downloadType: "custom", format: "jpg", quality, width: imageWidth },
            },
          });

          if (response.data.status !== "201") {
            this.state.failedFiles.push(file);
            console.error(`Error with downloading file ${file.subject} from Pixxio!`, response?.data);
            continue fileLoop;
          }

          downloadPath = response.data.downloadPath;

          response = await axios.head(downloadPath);
          let fileSize = response.headers["content-length"];
          // if file is bigger than 10 MB, resize image
          if (fileSize > FILE_SIZE_LIMIT) {
            let tooBigBy = fileSize / FILE_SIZE_LIMIT;
            tooBigBy = tooBigBy > 1 ? tooBigBy : 1.2; // in case we get stuck
            imageWidth = Math.floor(imageWidth / tooBigBy);
            console.log(
              `File "${file.subject}" is too big (${Math.round(fileSize / 1000000)} MB). Resizing image: ${
                file.imageWidth
              }px width -> ${imageWidth}px width`
            );
          } else {
            fileTooBig = false;
          }
        }
        const defaultLocale = this.props.sdk.locales.default;
        let fileName = file.originalFilename;
        let pos = fileName.lastIndexOf(".");
        fileName = fileName.substr(0, pos < 0 ? fileName.length : pos) + ".jpg";

        // import file (asset) into contentful
        let asset: any;
        let assetData = {
          fields: {
            title: { [defaultLocale]: this.state.installationParams.assetTitlePrefix + file.subject },
            description: { [defaultLocale]: file.description },
            file: {
              [defaultLocale]: { upload: downloadPath, contentType: "image/jpeg", fileName },
            },
          },
          sys: {
            id: ASSET_PREFIX + file.id,
            version: undefined,
          },
          metadata: {
            tags: [
              {
                sys: {
                  type: "Link",
                  linkType: "Tag",
                  id: this.state.pixxioTagId,
                },
              },
            ],
          },
        };
        let existingAsset: any = this.state.existingFiles.assets.find((existingAsset) => existingAsset.pixxioId === file.id);
        if (existingAsset !== undefined) {
          assetData.sys.version = existingAsset.version;
          asset = await this.props.sdk.space.updateAsset(assetData);
          console.log(`Updated Asset ${asset.sys.id}`, asset);
        } else {
          asset = await this.props.sdk.space.createAsset(assetData);
          console.log(`Created Asset ${asset.sys.id}`, asset);
        }
        await this.props.sdk.space.processAsset(asset, defaultLocale);
        await this.props.sdk.space.waitUntilAssetProcessed(asset.sys.id, this.props.sdk.locales.default);
        asset.sys.version++;
        console.log(`Processed Asset ${asset.sys.id}`, asset);
        asset = await this.props.sdk.space.publishAsset(asset);
        console.log(`Published Asset ${asset.sys.id}`, asset);

        // create mediaAsset entry
        if (asset.sys.id !== undefined) {
          let entry: any;
          const mediaAssetData = {
            fields: {
              title: { [defaultLocale]: file.subject },
              titleTag: { [defaultLocale]: file.subject },
              description: { [defaultLocale]: this.truncateString(file.description, 180) },
              copyright: {
                [defaultLocale]: file.licenses.map((license) => license.licenseName.replaceAll(/©\s|©/g, "")).toString(),
              },
              altText: { [defaultLocale]: this.truncateString(file.description, 256) },
              media: {
                [defaultLocale]: {
                  sys: {
                    type: "Link",
                    linkType: "Asset",
                    id: asset.sys.id,
                  },
                },
              },
              externalId: { [defaultLocale]: file.id },
              metadata: {
                [defaultLocale]: {
                  keywords: file.keywords,
                  category: file.category,
                  collections: file.collections.map((collection) => collection.collectionName),
                  status: file.fileStatus.statusTitle,
                },
              },
            },
            sys: {
              id: MEDIA_ASSET_PREFIX + file.id,
              version: undefined,
            },
            metadata: {
              tags: [
                {
                  sys: {
                    type: "Link",
                    linkType: "Tag",
                    id: this.state.pixxioTagId,
                  },
                },
              ],
            },
          };
          let existingMediaAsset: any = this.state.existingFiles.mediaAssets.find(
            (existingMediaAsset) => existingMediaAsset.pixxioId === file.id
          );

          if (existingMediaAsset !== undefined) {
            mediaAssetData.sys.version = existingMediaAsset.version;
            entry = await this.props.sdk.space.updateEntry(mediaAssetData);
            console.log(`Updated Entry ${entry.sys.id}`, entry);
          } else {
            entry = await this.props.sdk.space.createEntry("mediaAsset", mediaAssetData);
            console.log(`Created Entry ${entry.sys.id}`, entry);
          }
          entry = await this.props.sdk.space.publishEntry(entry);
          console.log(`Published Entry ${entry.sys.id}`, entry);
        }
      } catch (error) {
        console.error(`Error for importing file ${file.subject} into Contentful!`, error);
        this.state.failedFiles.push(file);
      }
      this.setState({ progress: ((index + 1) / this.state.selectedFiles.length) * 100 });
    }
    this.setState({ loading: false, selectedFiles: [], resultDialogOpen: true, progress: 0 });

    if (this.state.failedFiles.length > 0) {
      this.setState({
        snackbar: {
          open: true,
          message: "Import abgeschlossen! Einige Dateien wurden nicht importiert!",
          severity: "warning",
        },
      });
    } else {
      this.setState({
        snackbar: { open: true, message: "Alle Dateien wurden erfolgreich importiert!", severity: "success" },
      });
    }
  }

  render = () => {
    const { classes } = this.props;
    const userName = `${this.state.user.preName} ${this.state.user.surName}`;
    const collections = this.state.collections.map((collection) => (
      <ListItem button onClick={(event) => this.getCollection(collection.id)} key={collection.id}>
        <ListItemAvatar>
          <Avatar alt={collection?.filesSnippet?.[0]?.originalFilename} src={collection?.filesSnippet?.[0]?.imagePath} />
        </ListItemAvatar>
        <ListItemText
          primary={collection.collectionName}
          secondary={collection.quantity + " Dateien | Zuletzt geändert: " + collection.modifyDate}
        />
      </ListItem>
    ));
    let pixxioFiles: Array<any> = [];
    if (this.state?.files?.length > 0) {
      pixxioFiles = this.state.files.map((file: FileProps) => (
        <Grid key={file.id} item>
          <MediaCard
            selected={this.state.selectedFiles?.find((selectedFile) => selectedFile.id === file.id) !== undefined}
            imageClicked={this.imageClicked}
            file={file}
          ></MediaCard>
        </Grid>
      ));
    }
    const existingFiles = this.state.existingFiles.files.map((file) => (
      <ListItem key={file.id}>
        <ListItemAvatar>
          <Avatar alt={file.originalFilename} src={file.imagePath} />
        </ListItemAvatar>
        <ListItemText primary={file.subject} secondary={file.description} />
      </ListItem>
    ));
    const failedFiles = this.state.failedFiles.map((file) => (
      <ListItem key={file.id}>
        <ListItemAvatar>
          <Avatar alt={file.originalFilename} src={file.imagePath} />
        </ListItemAvatar>
        <ListItemText primary={file.subject} secondary={file.description} />
      </ListItem>
    ));

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ margin: "20px" }}>
          <Snackbar
            open={this.state.snackbar.open}
            autoHideDuration={5000}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ snackbar: { ...this.state.snackbar, open: false } });
            }}
          >
            <Alert
              onClose={(event: any, reason: any) => {
                if (reason === "clickaway") {
                  return;
                }

                this.setState({ snackbar: { ...this.state.snackbar, open: false } });
              }}
              severity={this.state.snackbar.severity}
            >
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                color="primary"
                value={this.state.progress}
                size={100}
                thickness={5}
              ></CircularProgress>
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5" component="div" color="inherit">{`${Math.round(this.state.progress)}%`}</Typography>
              </Box>
            </Box>
          </Backdrop>
          <Grid container style={{ marginBottom: "20px" }}>
            <Grid item xs={9}>
              <Typography variant="h4">Pixxio Importer</Typography>
            </Grid>
            <Grid item xs={3}>
              {this.state.loggedIn === true && (
                <Grid container justify="flex-end">
                  <Typography variant="body1" style={{ marginRight: "20px", display: "inline-block" }}>
                    Angemeldet als <br /> <strong>{userName}</strong>
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.logout();
                      this.setState({ snackbar: { open: true, message: "Abmeldung erfolgreich.", severity: "info" } });
                    }}
                  >
                    Abmelden
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {this.state.loggedIn === false && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Login
              </Typography>
              <Grid container>
                <TextField
                  id="refreshToken"
                  label="Pixxio Refresh Token"
                  name="refreshToken"
                  value={this.state.refreshToken}
                  onChange={this.handleChange}
                  variant="outlined"
                  style={{ marginRight: "20px" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.refreshToken.length === 0}
                  onClick={this.handleLogin}
                >
                  Anmelden
                </Button>
              </Grid>
            </Box>
          )}
          {this.state.loggedIn === true && (
            <div>
              <Paper style={{ marginBottom: "20px" }}>
                <Tabs
                  value={this.state.selectedTab}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Media" />
                  <Tab label="Collections" />
                </Tabs>
              </Paper>

              <TabPanel value={this.state.selectedTab} index={0}>
                <Box padding="20px" marginBottom="20px" border={2} borderRadius={16} borderColor="#2E75D4">
                  <Grid container justify="center" style={{ marginBottom: "20px" }}>
                    <Grid item xs={11}>
                      <Autocomplete
                        multiple
                        freeSolo={true}
                        id="searchBar"
                        options={[]}
                        value={this.state.searchBar}
                        onChange={(event: any, newValue: Array<string>) => {
                          this.setState({ searchBar: newValue });
                        }}
                        getOptionLabel={(option: string) => option}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Suchbegriffe"
                            variant="outlined"
                            placeholder="Suchbegriff mit Enter eingeben"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <HtmlTooltip
                        disableFocusListener
                        disableTouchListener
                        placement="top"
                        arrow
                        title={
                          <React.Fragment>
                            <Typography variant="body1" color="textSecondary" component="p">
                              Dateien werden nach Beschreibung, Keywords, Kategorie, Dateiname und Dateiformat gefiltert.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoOutlined />
                      </HtmlTooltip>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Autocomplete
                        multiple
                        id="fileStatusFilter"
                        value={this.state.selectedFileStati}
                        onChange={(event: any, newValue: Array<FileStatus>) => {
                          this.setState({ selectedFileStati: newValue });
                        }}
                        options={this.state.fileStati}
                        getOptionLabel={(option: FileStatus) => option.statusTitle}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip color="primary" label={option.statusTitle} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Datei Status"
                            variant="outlined"
                            placeholder="Filtern nach Datei Status"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Autocomplete
                        multiple
                        id="categoriesFilter"
                        value={this.state.selectedCategories}
                        onChange={(event: any, newValue: Array<string>) => {
                          this.setState({ selectedCategories: newValue });
                        }}
                        options={this.state.categories}
                        getOptionLabel={(option: string) => option}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Datei Kategorie"
                            variant="outlined"
                            placeholder="Filtern nach Datei Kategorie"
                          />
                        )}
                      />
                    </Grid>{" "}
                    <Grid container item xs={2}>
                      <Button variant="contained" color="primary" onClick={() => this.searchMedia()}>
                        Suchen
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {this.state.files.length > 0 && (
                  <div>
                    <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                      <Grid item xs={4}>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={() =>
                            this.setState({
                              selectedFiles: [],
                              page: { ...this.state.page, files: 1 },
                              selectedCategories: [],
                              selectedFileStati: [],
                              searchBar: [],
                              files: [],
                            })
                          }
                        >
                          Suche zurücksetzen
                        </Button>{" "}
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        <Button
                          className={classes.success}
                          variant="contained"
                          color="inherit"
                          disabled={this.state.selectedFiles?.length === 0}
                          onClick={this.confirmImport}
                        >
                          Auswahl importieren{" "}
                          {this.state?.selectedFiles?.length > 0 ? `(${this.state.selectedFiles.length})` : ``}
                        </Button>{" "}
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Button
                          style={{ marginRight: "10px" }}
                          variant="contained"
                          onClick={() => this.setState({ selectedFiles: [] })}
                        >
                          Alle abwählen
                        </Button>
                        <Button variant="contained" onClick={() => this.setState({ selectedFiles: this.state.files })}>
                          Seite auswählen {`(${this.state.files.length})`}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={1}>
                      {pixxioFiles}
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: "50px" }}>
                      <Pagination
                        count={Math.ceil((this.state.filesQuantity > 0 ? this.state.filesQuantity : 1) / PAGE_SIZE)}
                        page={this.state.page.files}
                        color="primary"
                        onChange={this.filesPageChange}
                      />
                    </Grid>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={1}>
                {this.state.collection === undefined && this.state.collections.length > 0 && (
                  <div>
                    <List>{collections}</List>
                    <Grid container justify="center" style={{ marginTop: "50px" }}>
                      <Pagination
                        count={Math.ceil(this.state.collectionsQuantity / PAGE_SIZE)}
                        page={this.state.page.collections}
                        color="primary"
                        onChange={this.collectionsPageChange}
                      />
                    </Grid>
                  </div>
                )}
                {this.state.collection != null && (
                  <div>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      {this?.state?.collection?.collectionName}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "20px" }}>
                      <strong>Anzahl Dateien:</strong> {this?.state?.collection?.quantity}
                      <br />
                      <strong>Zuletzt geändert:</strong> {this?.state?.collection?.modifyDate}
                      <br />
                      <strong>Beschreibung:</strong> {this?.state?.collection?.description}
                      <br />
                    </Typography>
                    <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                      <Grid item xs={4}>
                        <Button
                          color="default"
                          variant="contained"
                          startIcon={<ArrowBack />}
                          onClick={() =>
                            this.setState({
                              collection: undefined,
                              selectedFiles: [],
                              page: { ...this.state.page, files: 1 },
                            })
                          }
                        >
                          Zurück
                        </Button>{" "}
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        <Button
                          className={classes.success}
                          variant="contained"
                          color="inherit"
                          disabled={this.state.selectedFiles?.length === 0}
                          onClick={this.confirmImport}
                        >
                          Auswahl importieren{" "}
                          {this.state?.selectedFiles?.length > 0 ? `(${this.state.selectedFiles.length})` : ``}
                        </Button>{" "}
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Button
                          style={{ marginRight: "10px" }}
                          variant="contained"
                          onClick={() => this.setState({ selectedFiles: [] })}
                        >
                          Alle abwählen
                        </Button>
                        <Button variant="contained" onClick={() => this.setState({ selectedFiles: this.state.files })}>
                          Seite auswählen {`(${this.state.files.length})`}
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container justify="center" spacing={1}>
                      {pixxioFiles.length > 0 ? (
                        pixxioFiles
                      ) : (
                        <Typography style={{ margin: "50px" }} variant="body1">
                          Keine Bild-Dateien vorhanden.
                        </Typography>
                      )}
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: "50px" }}>
                      <Pagination
                        count={Math.ceil((this.state.filesQuantity > 0 ? this.state.filesQuantity : 1) / PAGE_SIZE)}
                        page={this.state.page.files}
                        color="primary"
                        onChange={this.filesPageChange}
                      />
                    </Grid>
                  </div>
                )}
              </TabPanel>
            </div>
          )}
          <Dialog
            open={this.state.confirmDialogOpen}
            onClose={() => {
              this.setState({ confirmDialogOpen: false });
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              className={this.state.existingFiles.files.length > 0 ? classes.warning : classes.info}
              style={{ textAlign: "center" }}
              id="responsive-dialog-title"
            >
              Import bestätigen
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Dateien, die importiert werden: {this.state.selectedFiles.length ?? "0"}
              </Typography>
              {this.state.existingFiles.files.length > 0 && (
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    <WarningRounded />
                  </div>
                  <Typography variant="body1">
                    <strong>
                      {this.state.existingFiles.files.length + " "}
                      Dateien sind bereits in Contentful vorhanden und würden überschrieben werden!
                    </strong>
                  </Typography>
                  <List>{existingFiles}</List>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ confirmDialogOpen: false });
                }}
                color="secondary"
                variant="contained"
              >
                Abbrechen
              </Button>
              <Button className={classes.success} onClick={this.importFiles} color="inherit" variant="contained">
                Bestätigen
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.resultDialogOpen}
            onClose={() => {
              this.setState({ failedFiles: [], resultDialogOpen: false });
            }}
            aria-labelledby="responsive-dialog-title"
          >
            {this.state.failedFiles.length > 0 ? (
              <DialogTitle className={classes.warning} style={{ textAlign: "center" }} id="responsive-dialog-title">
                Fehler beim Import
              </DialogTitle>
            ) : (
              <DialogTitle className={classes.success} style={{ textAlign: "center" }} id="responsive-dialog-title">
                Import abgeschlossen
              </DialogTitle>
            )}
            <DialogContent>
              {this.state.failedFiles.length > 0 ? (
                <div>
                  <Typography variant="body1">
                    <strong>Dateien, die nicht erfolgreich importiert wurden: {this.state.failedFiles.length ?? "0"}</strong>
                  </Typography>
                  <List>{failedFiles}</List>
                </div>
              ) : (
                <Typography variant="body1">Alle Dateien wurden erfolgreich importiert.</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.success}
                onClick={() => {
                  this.setState({ failedFiles: [], resultDialogOpen: false });
                }}
                color="inherit"
                variant="contained"
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
    );
  };
}

export default withStyles(useStyles)(Page);
