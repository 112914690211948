export default class LocalStorageManager {
  private spaceId: string;

  constructor(spaceId: string) {
    this.spaceId = spaceId;
  }

  /**
   *
   * @param key
   * @param value
   * @param ttl time to live in seconds
   */
  set(key: string, value: any, ttl: number) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 1000 * 60 * 60,
    };
    localStorage.setItem(this.spaceId + "-" + key, JSON.stringify(item));
  }

  /**
   *
   * @param key
   * @returns stored value
   */
  get(key: string) {
    const itemStr = localStorage.getItem(this.spaceId + "-" + key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(this.spaceId + "-" + key);
      return null;
    }
    return item.value;
  }

  remove(key: string) {
    return localStorage.removeItem(this.spaceId + "-" + key);
  }
}
